.donate-page {
    &--form {
        width: 100%;
        height: 800px;
        // height: ;

        &-iframe {
            border: none;
            width: 100%;
            height: 100%;
        }
    }
}