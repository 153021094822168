.testimonial {
    &-header {
        max-width: 500px;
    }
    
    &-carousel {
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 0;
            background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            z-index: 1;
        }
    
        &::after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            height: 100%;
            width: 0;
            background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            z-index: 1;
        }
    
        .owl-item {
            .testimonial-text {
                background: $light;
                transform: scale(.8);
                transition: .5s;
            }
    
            .testimonial-text *,
            .testimonial-item img {
                transition: .5s;
            }
    
    
            &.center {
                .testimonial-text {
                    background: $primary;
                    transform: scale(1);
                }
    
                .testimonial-text * {
                    color: $light !important;
                }
    
                .testimonial-item img {
                    background: $primary !important;
                } 
            }
        }
    
        .owl-nav {
            position: absolute;
            width: 350px;
            top: 15px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: space-between;
            opacity: 0;
            transition: .5s;
            z-index: 1;
    
            .owl {
                &-prev, 
                &-next {
                    position: relative;
                    color: $primary;
                    font-size: 45px;
                    transition: .5s;
                }
    
                &-prev:hover,
                &-next:hover {
                    color: $dark;
                }
            }
        }
    
        @include media-breakpoint-up('md'){
            &::before,
            &::after {
                width: 200px;
            }
        }
    
        @include media-breakpoint-up('lg'){
            &::before,
            &::after {
                width: 300px;
            }
        }
    
        &:hover {
            .owl-nav {
                width: 300px;
                opacity: 1;
            }
        }
    
        .testimonial-photo {
            width: 100px; 
            height: 100px;
        }
    }
    
}