.objective {
    max-width: 500px;

    &-item {
        box-shadow: 0 0 45px rgba(0, 0, 0, .06);

        &--description {
            height: 3.6rem;
            overflow: hidden;
        }
    }
}