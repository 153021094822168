.fil-info {
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;

    @keyframes slideleft {
        from {
            left: 100%;
        }
        to {
            left: -50%;
        }
    }

    .info {
        position: relative;
        animation: 30s linear infinite slideleft;
        white-space: nowrap;
    }

    
}