.text-primary {
    color: $primary !important;
}

.text-secondary {
    color: $secondary !important;
}

.text-yellow {
    color: $yellow !important;
}

.text-dark {
    color: $dark !important;
}

.text-success {
    color: $success !important;
}

.text-danger {
    color: $danger !important;
}

.bg-dark {
    background: $dark;
}