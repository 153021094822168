@mixin font($name, $file, $ext, $weight, $style){
    $url: 'fonts/';

    @font-face {
        font-family: $name;
        src: url($url + $name + '/' + $file + $ext) format('truetype');
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
}

// Saira
@include font('Saira', 'Saira-Black', '.otf', 900, normal);
@include font('Saira', 'Saira-BlackItalic', '.otf', 900, italic);
@include font('Saira', 'Saira-Bold', '.otf', bold, normal);
@include font('Saira', 'Saira-BoldItalic', '.otf', bold, italic);
@include font('Saira', 'Saira-ExtraBold', '.otf', 800, normal);
@include font('Saira', 'Saira-ExtraBoldItalic', '.otf', 800, italic);
@include font('Saira', 'Saira-SemiBold', '.otf', 700, normal);
@include font('Saira', 'Saira-SemiBoldItalic', '.otf', 700, italic);
@include font('Saira', 'Saira-ExtraLight', '.otf', 200, normal);
@include font('Saira', 'Saira-ExtraLightItalic', '.otf', 200, italic);
@include font('Saira', 'Saira-Light', '.otf', 300, normal);
@include font('Saira', 'Saira-LightItalic', '.otf', 300, italic);
@include font('Saira', 'Saira-Thin', '.otf', 100, normal);
@include font('Saira', 'Saira-ThinItalic', '.otf', 100, italic);
@include font('Saira', 'Saira-Medium', '.otf', 600, normal);
@include font('Saira', 'Saira-MediumItalic', '.otf', 600, italic);
@include font('Saira', 'Saira-Regular', '.otf', normal, normal);
@include font('Saira', 'Saira-Italic', '.otf', normal, italic);

// Inter
@include font('Inter', 'Inter-Black', '.otf', 900, normal);
@include font('Inter', 'Inter-BlackItalic', '.otf', 900, italic);
@include font('Inter', 'Inter-Bold', '.otf', bold, normal);
@include font('Inter', 'Inter-BoldItalic', '.otf', bold, italic);
@include font('Inter', 'Inter-ExtraBold', '.otf', 800, normal);
@include font('Inter', 'Inter-ExtraBoldItalic', '.otf', 800, italic);
@include font('Inter', 'Inter-SemiBold', '.otf', 700, normal);
@include font('Inter', 'Inter-SemiBoldItalic', '.otf', 700, italic);
@include font('Inter', 'Inter-ExtraLight', '.otf', 200, normal);
@include font('Inter', 'Inter-ExtraLightItalic', '.otf', 200, italic);
@include font('Inter', 'Inter-Light', '.otf', 300, normal);
@include font('Inter', 'Inter-LightItalic', '.otf', 300, italic);
@include font('Inter', 'Inter-Medium', '.otf', 600, normal);
@include font('Inter', 'Inter-MediumItalic', '.otf', 600, italic);
@include font('Inter', 'Inter-Regular', '.otf', normal, normal);
@include font('Inter', 'Inter-Italic', '.otf', normal, italic);

//Font weight
.fw-black {
    font-weight: 900!important;
}

.fw-800 {
    font-weight: 800!important;
}

.fw-bold {
    font-weight: bold!important;
}

.fw-700 {
    font-weight: 700!important;
}

.fw-500 {
    font-weight: normal!important;
}

//Font styles
.fstyle-normal {
    font-style: normal;
}

.fstyle-italic {
    font-style: italic;
}

//Font Family
.ff-saira {
    font-family: 'Saira';
}

.ff-inter {
    font-family: 'Inter';
}