.fixed-top {
    transition: .5s;
}

.top-bar {
    min-height: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, .07);
}

.navbar {

    .navbar-brand {
        .app-logo {
            width: 150px;
            height: auto;
            object-fit: contain;
        }
    }

    .dropdown-toggle::after {
        border: none;
        content: "\f107";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        vertical-align: middle;
        margin-left: 8px;
    }

    &-nav {
        .nav-link {
            margin-right: 30px;
            padding: 25px 0;
            color: $white;
            font-weight: 500;
            outline: none;
            text-align: center;

            &:hover, &.active {
                color: $primary;
            }
        }
    }

    .nav-item {
        font-weight: 700;
    }

    @include media-breakpoint-down('lg'){
        &-nav {
            margin-top: 10px;
            border-top: 1px solid rgba(0, 0, 0, .07);
            background: $black-2;

            .nav-link {
                padding: 10px 0;
            }
        }
    }

    @include media-breakpoint-up('lg'){
        .nav-item {
            font-weight: 700;
            
            .dropdown-menu {
                display: block;
                visibility: hidden;
                top: 100%;
                transform: rotateX(-75deg);
                transform-origin: 0% 0%;
                transition: .5s;
                opacity: 0;
            }

            &:hover {
                .dropdown-menu {
                    transform: rotateX(0deg);
                    visibility: visible;
                    transition: .5s;
                    opacity: 1;
                }
            }
        }
    }
}
