.activities-page {
    .preview-gallery {
        margin-top: 4rem;
        margin-bottom: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .preview-container {
        position: relative;
        width: 30rem;
        height: 25rem;
        border: 1px solid #000;

        .preview-img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &:hover {
            .preview-container--overlay {
                height: 100%;
                opacity: 1;
            }
        }

        &--overlay {
            position: absolute;
            width: 100%;
            height: 0;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, .5);
            overflow: hidden;
            opacity: 0;
            transition: .5s;
        }
    }

    .content-title {
        margin-top: 8rem;
    }
}