.causes {
    &-title-container {
        max-width: 500px
    }

    &-item {
        .progress {
            height: 5px;
            border-radius: 0;
            overflow: visible;

            .progress-bar {
                position: relative;
                overflow: visible;
                width: 0px;
                border-radius: 0;
                transition: 5s;

                span {
                    position: absolute;
                    top: -7px;
                    right: 0;
                    width: 40px;
                    height: 19px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    background: $primary;
                    color: #FFFFFF;
                }
                
            }
        }

        &:hover {
            .causes-overlay {
                height: 100%;
                opacity: 1;
            }
        }

        .causes-overlay {
            position: absolute;
            width: 100%;
            height: 0;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, .5);
            overflow: hidden;
            opacity: 0;
            transition: .5s;
        }
    }
}
