.btn {
    font-weight: 500;
    transition: .5s;
    display: flex;
    align-items: center;
    justify-content: center;

    &.btn-primary,
    &.btn-outline-primary:hover {
        color: #FFFFFF;
    }

    &.btn-primary:hover {
        color: $primary;
        background: transparent;
    }

    &-square {
        width: 38px;
        height: 38px;
    }

    &-sm-square {
        width: 32px;
        height: 32px;
    }

    &-lg-square {
        width: 48px;
        height: 48px;
    }

    &-square,
    &-sm-square,
    &-lg-square {
        padding: 0;
        font-weight: normal;
    }

    &-outline-primary {
        color: $primary;
        border: $btn-border-width solid $primary;

        &:hover {
            background: $primary;
            color: $white;
        }
    }

    &-30 {
        width: 30%;

        @include media-breakpoint-down('md'){
            width: 60%;
        }
    }
    
}