.about {
    &-imgs-container {
        min-height: 400px;

        .img-fit-cover {
            object-fit: cover
        }

        .img-top-over {
            width: 200px; 
            height: 200px
        }
    }
}