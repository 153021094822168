.small-size-1 {
    font-size: $small-size-1 !important;
}

.small-size-2 {
    font-size: $small-size-2 !important;
}

.small-size-3 {
    font-size: $small-size-3 !important;
}

.init-size {
    font-size: $init-size !important;

    @include media-breakpoint-down('md'){
        font-size: $small-size-3 !important;
    }
}

.large-size-1 {
    font-size: $large-size-1 !important;

    @include media-breakpoint-down('md'){
        font-size: $init-size !important;
    }
}

.large-size-2 {
    font-size: $large-size-2 !important;

    @include media-breakpoint-down('md'){
        font-size: $init-size !important;
    }
}

.large-size-3 {
    font-size: $large-size-3 !important;

    @include media-breakpoint-down('md'){
        font-size: $large-size-1 !important;
    }
}

.large-size-4 {
    font-size: $large-size-4 !important;

    @include media-breakpoint-down('md'){
        font-size: $large-size-2 !important;
    }
}

.large-size-5 {
    font-size: $large-size-5 !important;

    @include media-breakpoint-down('md'){
        font-size: $large-size-2 !important;
    }
}

.large-size-6 {
    font-size: $large-size-6 !important;

    @include media-breakpoint-down('md'){
        font-size: $large-size-3 !important;
    }
}

.large-size-7 {
    font-size: $large-size-7 !important;

    @include media-breakpoint-down('md'){
        font-size: $large-size-4 !important;
    }
}

.home-title {
    font-size: 3rem !important;

    @include media-breakpoint-down('lg'){
        font-size: 2rem !important;
    }
}