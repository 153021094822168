.donate {
    background: rgb(0, 29, 35);

    .btn-group .btn-light:hover,
    .btn-group input[type="radio"]:checked+label {
        color: $primary;
        border-color: $primary;
    }

    .btn-submit-donate {
        height: 60px;
    }
}