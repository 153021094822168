.team {
    width: 75vw;

    @include media-breakpoint-up('md'){
        max-width: 100%;
    }

    &-item {
        img {
            position: relative;
            top: 0;
            transition: .5s;
        }

        .team-text {
            position: relative;
            height: 100px;
            transition: .5s;

            .team-social {
                opacity: 0;
                transition: .5s;

                .btn {
                    display: inline-flex;
                    color: $primary;
                    background: $white;
                    border-radius: 40px;

                    &:hover {
                        color: $white;
                        background: $primary;
                    }
                }
            }
        }

        &:hover {
            img {
                top: -45px;
            }

            .team-text {
                margin-top: -60px;
                height: 160px;

                .team-social {
                    opacity: 1;
                }
            }
        }
    }
}
