.adhesion-page {
    .msg-area {
        height: 100px;
    }

    &--form {
        margin-top: 2rem;
        width: 100%;
        height: 800px;
        // height: ;

        &-iframe {
            border: none;
            width: 100%;
            height: 100%;
        }
    }
}