.carousel {
    &-caption {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: rgba(0, 29, 35, .8);
        z-index: 1;
    }

    &-control-prev,
    &-control-next {
        width: 15%;
    }

    &-control-prev-icon,
    &-control-next-icon {
        width: 3rem;
        height: 3rem;
        background-color: $dark;
        border: 12px solid $dark;
        border-radius: 3rem;
    }

}

@include media-breakpoint-down('lg'){
    #header-carousel {
        .carousel-item {
            position: relative;
            min-height: 450px;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

}