.footer {
    .btn {
        &.btn-link {
            display: block;
            margin-bottom: 5px;
            padding: 0;
            text-align: left;
            color: rgba(255,255,255,0.5);
            font-weight: normal;
            text-transform: capitalize;
            transition: .3s;

            &::before {
                position: relative;
                content: "\f105";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                color: rgba(255,255,255,0.5);
                margin-right: 10px;
            }

            &:hover {
                color: $light;
                letter-spacing: 1px;
                box-shadow: none;
            }
        }

        &.btn-square {
            color: rgba(255,255,255,0.5);
            border: 1px solid rgba(255,255,255,0.5);

            &:hover {
                color: $secondary;
                border-color: $light;
            }
            
        }
    }

    .copyright {
        padding: 25px 0;
        font-size: 15px;
        border-top: 1px solid rgba(256, 256, 256, .1);

        a {
            color: $secondary;

            &:hover {
                color: $primary;
            }
        }
    }

    .newsletter-container {
        max-width: 400px;
    }
}