/* Colors**/

// Basic colors
$black: #000000;
$black-2: #001D23;
$dark: #001D23;
$white: #ffffff;
$white-2: #F8F8F9;

// Grey colors
$grey-1: #f2f2f2;
$grey-2: #f7f7f7;
$grey-3: #e7e7e7;
$grey-4: #b9b9b9;
$grey-5: #a7a7a7;
$grey-6: #747474;
$grey-7: #565656;
$grey-8: #E5E5E5;

// Green colors
$green-1: #85ffc8;
$green-2: #0aff91;
$green-3: #00d072;

// Beige Color
$beige: #f2f1ee;
$beige-2: #FFF0E6;

// Error color
$error: #ff0000;

$orange: #FF6F0F;
$yellow:  #ffc107;
$cyan:    #0dcaf0;

$primary:       $orange;
$secondary:     $beige-2;
$success:       $green-3;
$info:          $cyan;
$warning:       $yellow;
$danger:        $error;
$light:         $white-2;

$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light
);


$font-family-base: 'Inter', sans-serif;

$headings-font-family: 'Saira', sans-serif;

$body-color: #787878;

/* * Font Sizes */ 
$small-size-1: 0.625rem; //10px 
$small-size-2: 0.75rem; //12px 
$small-size-3: 0.875rem; //14px 
$init-size : 1rem; //16px 
$large-size-1: 1.125rem; //18px 
$large-size-2: 1.25rem; //20px 
$large-size-3: 1.375rem; //22px 
$large-size-4: 1.5rem; //24px 
$large-size-5: 1.75rem; // 28px 
$large-size-6: 1.875rem; //30px 
$large-size-7: 2rem; //32px

$link-decoration: none;

$enable-negative-margins: true;

//Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";